import React from 'react';
import styles from '../Loader.module.scss';
import { LoaderProps } from '../types';

export const Loader12 = ({ color }: { color: LoaderProps['color'] }) => {
  return (
    <svg
      className={`${styles.loader} ${styles[color]}`}
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_72_11)">
        <path d="M7.96421 1.41028C8.18317 0.898659 7.94642 0.297251 7.4054 0.166903C6.33892 -0.0900424 5.21556 -0.0511439 4.15926 0.289339C2.78118 0.733539 1.61022 1.66108 0.862352 2.90088C0.114485 4.14067 -0.159836 5.60908 0.0899882 7.03526C0.339813 8.46144 1.09693 9.74917 2.22169 10.6609C3.34644 11.5727 4.76297 12.047 6.20997 11.9963C7.65698 11.9457 9.03686 11.3734 10.0951 10.3852C11.1533 9.39703 11.8185 8.05949 11.968 6.61933C12.0825 5.51544 11.8881 4.40833 11.4161 3.4181C11.1766 2.91575 10.5392 2.80856 10.084 3.12864C9.62874 3.44871 9.53344 4.07578 9.72943 4.59663C9.94554 5.17098 10.0277 5.7919 9.96344 6.41131C9.86419 7.36775 9.42242 8.25604 8.71963 8.91233C8.01684 9.56861 7.10044 9.94863 6.13945 9.98228C5.17846 10.0159 4.23772 9.70095 3.49074 9.09543C2.74377 8.4899 2.24095 7.63469 2.07504 6.68754C1.90913 5.74038 2.09131 4.76518 2.58798 3.94181C3.08466 3.11843 3.86232 2.50243 4.77752 2.20743C5.37022 2.01638 5.99472 1.96831 6.60135 2.06091C7.15149 2.14488 7.74526 1.9219 7.96421 1.41028Z" />
      </g>
      <defs>
        <clipPath id="clip0_72_11">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
