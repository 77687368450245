import { USER_TOKEN_KEY_LOCALSTORAGE } from '@constants/index';

export const tokenInstance = {
  setToken: (token: string) => {
    localStorage.setItem(USER_TOKEN_KEY_LOCALSTORAGE, token);
  },
  getToken: () => {
    const token = localStorage.getItem(USER_TOKEN_KEY_LOCALSTORAGE);
    // if (!token) throw new Error('Token not provided');
    return token;
  },
  clearToken: () => {
    localStorage.removeItem(USER_TOKEN_KEY_LOCALSTORAGE);
  },
};
