import { createContext, useContext } from 'react';

interface DropdownContextType {
  size: 'm' | 'l';
  multiple: boolean;
  selectedValues: string[] | string;
  selectItem: (value: string, label: string) => void;
}

export const DropdownContext = createContext<DropdownContextType | undefined>(
  undefined,
);

export const useDropdownContext = () => {
  const context = useContext(DropdownContext);
  if (context) return context;
  throw new Error(
    'useDropdownContext must be used within a DropdownContextProvider',
  );
};
