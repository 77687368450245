import { ADMIN_ROLE, ROUTES } from '@constants/index';
import { useGetUserInfo } from '@features/auth/model/useGetUserInfo';
import { Loader } from '@ui/Loader/Loader';
import { FunctionComponent } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

export const AdminRouter: FunctionComponent = () => {
  const userRole = useGetUserInfo((state) => state?.data?.role);
  const loading = useGetUserInfo((state) => state.loading);

  if (loading) {
    return <Loader size="xl" color="accent" wrap />;
  }

  if (userRole !== ADMIN_ROLE) {
    return (
      <Navigate to={ROUTES.APPLICATIONS} state={{ from: location }} replace />
    );
  }

  return <Outlet />;
};
