import type { State } from '@appType/index';

import { Loader } from '@ui/Loader/Loader';
import clsx from 'clsx';
import { ButtonHTMLAttributes, FunctionComponent } from 'react';
import styles from './Button.module.scss';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  state?: State;
  colorType?: 'primary' | 'secondary';
  fullWidth?: boolean;
  size?: 'L' | 'M' | 'R' | 'S';
}

type Props = ButtonProps;

export const Button: FunctionComponent<Props> = ({
  children,
  state = 'idle',
  colorType = 'primary',
  fullWidth = false,
  size = 'R',
  ...buttonProps
}) => {
  const btnStyles = clsx({
    [styles.btn]: true,
    [styles.primary]: colorType === 'primary',
    [styles.secondary]: colorType === 'secondary',
    [styles.full]: fullWidth,
    [styles.large]: size === 'L',
    [styles.medium]: size === 'M',
    [styles.regular]: size === 'R',
    [styles.small]: size === 'S',
    [styles.disabled]: buttonProps.disabled,
  });

  return (
    <button className={btnStyles} {...buttonProps}>
      {state === 'idle' && children}
      {state === 'pending' && <Loader size="l" color="london" />}
    </button>
  );
};
