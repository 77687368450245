import { Loader12 } from './components/Loader12';
import { Loader16 } from './components/Loader16';
import { Loader20 } from './components/Loader20';
import { Loader24 } from './components/Loader24';
import { Loader32 } from './components/Loader32';
import styles from './Loader.module.scss';
import { LoaderFunction, LoaderProps } from './types';

const sizeObject: Record<LoaderProps['size'], LoaderFunction> = {
  xl: (color: LoaderProps['color']) => <Loader32 color={color} />,
  l: (color: LoaderProps['color']) => <Loader24 color={color} />,
  m: (color: LoaderProps['color']) => <Loader20 color={color} />,
  s: (color: LoaderProps['color']) => <Loader16 color={color} />,
  xs: (color: LoaderProps['color']) => <Loader12 color={color} />,
};

export const Loader = ({
  size = 'xl',
  color = 'accent',
  wrap = false,
}: LoaderProps) => {
  const loader = sizeObject[size](color);

  if (wrap) {
    return <div className={styles.container}>{loader}</div>;
  }

  return loader;
};
