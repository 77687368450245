import { Input } from '@features/form/ui/Input/Input';
import EyeClose from '@icons/EyeClosed.svg?react';
import EyeOpen from '@icons/EyeOpen.svg?react';
import { FunctionComponent, HTMLProps, MouseEvent, useState } from 'react';

interface Props {
  autoComplete: HTMLProps<HTMLInputElement>['autoComplete'];
  value: string;
  setValue: (value: string) => void;
  isError?: boolean;
  errorText?: string;
}

export const PasswordInput: FunctionComponent<Props> = ({
  value,
  setValue,
  autoComplete,
  isError,
  errorText,
}) => {
  const [isSeen, setIsSeen] = useState(false);

  const handleToggle = (
    e: MouseEvent<SVGSVGElement, globalThis.MouseEvent>,
  ) => {
    e.stopPropagation();
    setIsSeen((prev) => !prev);
  };

  return (
    <Input
      autoComplete={autoComplete}
      inputMode="text"
      type={isSeen ? 'text' : 'password'}
      value={value}
      placeholder="Пароль"
      onChange={(e) => setValue(e.target.value)}
      isError={isError}
      errorText={errorText ?? ''}
      iconAfter={
        !isSeen ? (
          <EyeOpen onClick={handleToggle} />
        ) : (
          <EyeClose onClick={handleToggle} />
        )
      }
    />
  );
};
