import { ROUTES } from '@constants/index';
import { Loader } from '@ui/Loader/Loader';
import { FunctionComponent } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from '../shared/hooks/useAuth';

export const GuestRoute: FunctionComponent = () => {
  const { isAuthenticated, isLoading } = useAuth();

  if (isLoading) {
    return <Loader size="xl" color="accent" wrap />;
  }

  if (isAuthenticated) {
    return <Navigate to={ROUTES.APPLICATIONS} replace />;
  }

  return <Outlet />;
};
