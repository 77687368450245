import React from 'react';
import styles from '../Loader.module.scss';
import { LoaderProps } from '../types';

export const Loader16 = ({ color }: { color: LoaderProps['color'] }) => {
  return (
    <svg
      className={`${styles.loader} ${styles[color]}`}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_72_13)">
        <path d="M10.7526 1.5681C10.9707 1.0584 10.7353 0.46171 10.2023 0.309083C8.68407 -0.125674 7.06298 -0.103298 5.54567 0.385784C3.70824 0.978051 2.14696 2.21477 1.1498 3.86783C0.152647 5.52089 -0.213115 7.47878 0.119984 9.38035C0.453083 11.2819 1.46257 12.9989 2.96225 14.2146C4.46193 15.4303 6.35062 16.0627 8.27997 15.9951C10.2093 15.9275 12.0491 15.1646 13.4601 13.847C14.8711 12.5294 15.758 10.746 15.9573 8.82577C16.1218 7.2401 15.8083 5.64946 15.0688 4.25405C14.8092 3.76417 14.1767 3.65729 13.7232 3.97616C13.2696 4.29503 13.1693 4.9188 13.4081 5.41916C13.8803 6.40869 14.0748 7.51522 13.9603 8.61853C13.811 10.0568 13.1467 11.3927 12.0898 12.3796C11.033 13.3665 9.65485 13.938 8.2097 13.9886C6.76456 14.0392 5.34985 13.5656 4.22654 12.655C3.10322 11.7444 2.34708 10.4583 2.09757 9.03393C1.84807 7.60958 2.12204 6.14305 2.86895 4.90485C3.61585 3.66665 4.78531 2.7403 6.16162 2.29667C7.21736 1.95636 8.34019 1.91771 9.40602 2.17497C9.94495 2.30506 10.5345 2.0778 10.7526 1.5681Z" />
      </g>
      <defs>
        <clipPath id="clip0_72_13">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
