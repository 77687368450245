import { ROUTES } from '@constants/index';
import { useGetUserInfo } from '@features/auth/model/useGetUserInfo';
import { Loader } from '@ui/Loader/Loader';
import { FunctionComponent, useEffect } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from '../shared/hooks/useAuth';

export const PrivateRouter: FunctionComponent = () => {
  const { isAuthenticated, isLoading } = useAuth();
  const getUserInfo = useGetUserInfo((state) => state.execute);

  useEffect(() => {
    getUserInfo();
  }, []);

  if (isLoading) {
    return <Loader size="xl" color="accent" wrap />;
  }

  if (!isAuthenticated) {
    return <Navigate to={ROUTES.LOGIN} />;
  }

  return <Outlet />;
};
