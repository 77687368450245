import React from 'react';
import styles from '../Loader.module.scss';
import { LoaderProps } from '../types';

export const Loader32 = ({ color }: { color: LoaderProps['color'] }) => {
  return (
    <svg
      className={`${styles.loader} ${styles[color]}`}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_72_19)">
        <path d="M21.4602 3.24138C21.9213 2.16389 21.4235 0.901656 20.2945 0.587073C17.2899 -0.25013 14.0893 -0.194787 11.0913 0.771568C7.41649 1.9561 4.29392 4.42955 2.29961 7.73566C0.305293 11.0418 -0.42623 14.9576 0.239968 18.7607C0.906167 22.5638 2.92515 25.9978 5.9245 28.4292C8.92385 30.8605 12.7012 32.1253 16.5599 31.9902C20.4186 31.8551 24.0983 30.3292 26.9202 27.694C29.7422 25.0587 31.516 21.492 31.9145 17.6515C32.2397 14.5185 31.6316 11.3757 30.1908 8.60934C29.6494 7.56986 28.3115 7.34403 27.3528 8.01811C26.394 8.69219 26.1835 10.0109 26.6745 11.0751C27.5537 12.9809 27.9123 15.1002 27.693 17.2134C27.4002 20.0351 26.0969 22.6558 24.0235 24.592C21.9501 26.5282 19.2465 27.6493 16.4114 27.7486C13.5763 27.8479 10.8009 26.9186 8.59716 25.1322C6.39342 23.3457 4.91001 20.8227 4.42053 18.0284C3.93105 15.2341 4.46852 12.357 5.93382 9.92789C7.39911 7.49876 9.69338 5.68143 12.3934 4.81111C14.4156 4.1593 16.5632 4.0716 18.6097 4.53751C19.7524 4.79767 20.9991 4.31886 21.4602 3.24138Z" />
      </g>
      <defs>
        <clipPath id="clip0_72_19">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
