import { RouterProvider } from 'react-router-dom';
import { Bounce, ToastContainer } from 'react-toastify';
import { AuthProvider } from '../shared/hooks/useAuth';
import { router } from './router';

import 'react-toastify/dist/ReactToastify.css';
import '../index.scss';

function App() {
  return (
    <AuthProvider>
      <ToastContainer
        pauseOnFocusLoss
        pauseOnHover
        position="top-right"
        autoClose={7000}
        transition={Bounce}
      />
      <RouterProvider router={router} />
    </AuthProvider>
  );
}

export default App;
