import React from 'react';
import styles from '../Loader.module.scss';
import { LoaderProps } from '../types';

export const Loader24 = ({ color }: { color: LoaderProps['color'] }) => {
  return (
    <svg
      className={`${styles.loader} ${styles[color]}`}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_72_15)">
        <path d="M16.0843 2.45624C16.4361 1.63421 16.0564 0.671014 15.1945 0.432981C12.9487 -0.187264 10.5582 -0.14326 8.31851 0.578676C5.56237 1.46708 3.22044 3.32216 1.7247 5.80175C0.22897 8.28134 -0.319672 11.2182 0.179976 14.0705C0.679625 16.9229 2.19386 19.4983 4.44337 21.3219C6.69289 23.1454 9.52594 24.094 12.4199 23.9926C15.314 23.8913 18.0737 22.7469 20.1902 20.7705C22.3066 18.7941 23.637 16.119 23.9359 13.2387C24.1788 10.898 23.7272 8.5502 22.6557 6.48132C22.2445 5.68734 21.2236 5.51508 20.4922 6.02936C19.7607 6.54362 19.6005 7.54974 19.9716 8.36323C20.6156 9.7749 20.8774 11.3419 20.7152 12.9044C20.497 15.0075 19.5256 16.9608 17.9802 18.4039C16.4348 19.847 14.4197 20.6826 12.3066 20.7566C10.1935 20.8306 8.12492 20.138 6.4824 18.8065C4.83988 17.475 3.73423 15.5945 3.36941 13.5118C3.00458 11.4291 3.40518 9.28475 4.49732 7.47424C5.58945 5.66372 7.29945 4.3092 9.3119 3.66052C10.8071 3.17857 12.3943 3.1104 13.9087 3.44838C14.7813 3.64315 15.7325 3.27827 16.0843 2.45624Z" />
      </g>
      <defs>
        <clipPath id="clip0_72_15">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
