import { ZOD_VALIDATION_ERROR } from '@constants/index';
import { sanitizeInput } from '@utils/sanitizeInput';
import { useState } from 'react';
import { ZodError, ZodType } from 'zod';

interface Props<T> {
  initialValue: T;
  schema?: ZodType<T>;
}

export const useHandleInput = <T>({ initialValue, schema }: Props<T>) => {
  const [value, setValue] = useState<T>(initialValue);
  const [validation, setValidation] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  const handleValue = (value: T) => {
    // @ts-ignore
    if (typeof value === 'string') {
      const s = sanitizeInput(value);
      setValue(s);
    } else {
      setValue(value);
    }
  };

  const handleError = (error: string) => {
    setError(error);
  };

  const resetValue = () => {
    setValue(initialValue);
    handleError('');
  };

  const handleValidationOnBlur = () => {
    setValidation(true);
  };

  const handleValidationOnFocus = () => {
    setValidation(false);
  };

  const zodValidation = () => {
    if (schema === undefined) throw new Error(ZOD_VALIDATION_ERROR);
    try {
      schema.parse(value);
      setError('');
    } catch (err) {
      if (err instanceof ZodError) {
        setError(err?.errors[0]?.message ?? '');
      }
    }
  };

  return { value, handleValue, error, handleError, resetValue, zodValidation };
};
