import React from 'react';
import styles from '../Loader.module.scss';
import { LoaderProps } from '../types';

export const Loader20 = ({ color }: { color: LoaderProps['color'] }) => {
  return (
    <svg
      className={`${styles.loader} ${styles[color]}`}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_72_17)">
        <path d="M13.2971 2.2957C13.6491 1.47325 13.2687 0.507018 12.4002 0.292297C10.6056 -0.151421 8.71171 -0.0914043 6.93209 0.482229C4.63531 1.22256 2.6837 2.76847 1.43725 4.83479C0.190808 6.90111 -0.266394 9.34847 0.14998 11.7254C0.566354 14.1024 1.82822 16.2486 3.70281 17.7682C5.57741 19.2878 7.93828 20.0783 10.35 19.9939C12.7616 19.9094 15.0614 18.9557 16.8251 17.3087C18.5888 15.6617 19.6975 13.4325 19.9466 11.0322C20.1396 9.17241 19.8065 7.30713 19.001 5.64312C18.6112 4.83789 17.5872 4.6656 16.8554 5.18013C16.1235 5.69467 15.9689 6.70231 16.2951 7.53535C16.6857 8.53301 16.8364 9.61678 16.7242 10.6978C16.5558 12.3205 15.8064 13.8275 14.614 14.941C13.4217 16.0544 11.867 16.6991 10.2366 16.7562C8.6062 16.8133 7.01017 16.2789 5.74288 15.2516C4.47559 14.2243 3.62253 12.7734 3.34104 11.1665C3.05956 9.55954 3.36865 7.90505 4.21129 6.50814C5.05393 5.11123 6.37328 4.06615 7.92599 3.56566C8.96041 3.23223 10.0519 3.15549 11.1088 3.3312C11.9913 3.47791 12.9452 3.11815 13.2971 2.2957Z" />
      </g>
      <defs>
        <clipPath id="clip0_72_17">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
