import { ErrorDescription } from '@ui/ErrorDescription/ErrorDescription';
import clsx from 'clsx';
import { FunctionComponent, useRef } from 'react';
import styles from './Input.module.scss';
import type { InputProps } from './types';

export const Input: FunctionComponent<InputProps> = ({
  isError,
  // @ts-expect-error Проверка идет во время runtime на 83 строке
  errorText,
  iconAfter,
  iconBefore,
  ...inputProps
}) => {
  const containerStyles = clsx({
    [styles.container]: true,
    [styles.error]: isError,
  });

  const inputStyles = clsx({
    [styles.input]: true,
  });

  const inputRef = useRef<HTMLInputElement>(null);

  const handleInputFocus = () => {
    if (inputRef.current === null) return;

    inputRef.current.focus();
  };

  return (
    <>
      <div onClick={handleInputFocus} className={containerStyles}>
        {iconBefore && <div className={styles.icon}>{iconBefore}</div>}
        <input ref={inputRef} className={inputStyles} {...inputProps} />
        {iconAfter && <div className={styles.icon}>{iconAfter}</div>}
      </div>
      {errorText && (
        <div className={styles.error_text}>
          <ErrorDescription text={errorText} />
        </div>
      )}
    </>
  );
};
