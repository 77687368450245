import { Loader } from '@ui/Loader/Loader';
import clsx from 'clsx';
import { Suspense } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { Navbar } from '../navbar/Navbar';
import styles from './Layout.module.scss';

export const Layout = () => {
  const location = useLocation();

  const containerStyles = clsx({
    [styles.container]: true,
  });

  const wrapperStyle = clsx({
    [styles.layout_wrapper]: true,
    [styles.white]: !location.pathname.includes('detail'),
  });

  return (
    <div className={wrapperStyle}>
      <section className={containerStyles}>
        <div className={styles.navbar}>
          <Navbar />
          <div className={styles.divider_container}>
            <hr className={styles.divider} />
          </div>
        </div>

        <main className={styles.main}>
          <Suspense fallback={<Loader size="xl" color="accent" />}>
            <Outlet />
          </Suspense>
        </main>
      </section>
    </div>
  );
};
