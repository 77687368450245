import { ROUTES } from '@constants/index';
import { useGetUserInfo } from '@features/auth/model/useGetUserInfo';
import Logo from '@icons/Logo.svg?react';
import Shevron from '@icons/Shevron.svg?react';
import { Version } from '@ui/Version/Version';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../../shared/hooks/useAuth';
import styles from './Navbar.module.scss';

export const Navbar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [show, setShow] = useState(false);
  const { logout } = useAuth();

  const email = useGetUserInfo((state) => state.data?.email);
  const name = useGetUserInfo((state) => state.data?.name);
  const surname = useGetUserInfo((state) => state.data?.surname);
  const role = useGetUserInfo((state) => state.data?.role);

  const shevronStyles = clsx({
    [styles.icon]: true,
    [styles.open]: show,
  });

  useEffect(() => {
    const handleClickOutside = () => {
      setShow(false);
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <header className={styles.header}>
      <div
        onClick={() => {
          if (location.pathname !== '/applications') {
            navigate(ROUTES.APPLICATIONS);
          }
        }}
        className={styles.logo_container}
      >
        <Logo />
        <Version />
      </div>
      <div className={styles.container}>
        <div className={styles.user_info}>
          <span className={styles.name}>{`${name ?? ''} ${
            surname ?? ''
          }`}</span>
          <span className={styles.email}>{email ?? ''}</span>
        </div>
        <div
          onClick={(e) => {
            e.stopPropagation();
            setShow((prev) => !prev);
          }}
          className={styles.shevron_container}
        >
          <Shevron className={shevronStyles} />
        </div>
      </div>
      {show && (
        <div className={styles.pop}>
          <span
            onClick={() => navigate(ROUTES.APPLICATIONS)}
            className={styles.item}
          >
            Результаты скоринга
          </span>
          {role === 'admin' && (
            <span
              onClick={() => navigate(ROUTES.USERS)}
              className={styles.item}
            >
              Список пользователей
            </span>
          )}

          <span
            onClick={() => logout()}
            className={`${styles.item} ${styles.exit}`}
          >
            Выйти
          </span>
        </div>
      )}
    </header>
  );
};
