import { PasswordInput } from '@features/form';
import { useHandleInput } from '@features/form/model/useHandleValue';
import { Input } from '@features/form/ui/Input/Input';
import { useAuth } from '@hooks/useAuth';
import Logo from '@icons/Logo.svg?react';
import { Button } from '@ui/Button/Button';
import { ErrorDescription } from '@ui/ErrorDescription/ErrorDescription';
import { Version } from '@ui/Version/Version';
import { sanitizeInput } from '@utils/sanitizeInput';
import { FormEvent } from 'react';
import { z } from 'zod';
import { useLogin } from '../../model/useLogin';
import styles from './Login.module.scss';

const emailSchema = z
  .string()
  .min(1, { message: 'Поле должно быть заполнено' })
  .email({ message: 'Неверная почта' });
const passwordSchema = z
  .string()
  .min(2, 'Пароль должен содержать как минимум 2 символов');

export const Login = () => {
  const { login } = useAuth();

  const {
    value: email,
    handleValue: handleEmail,
    error: emailError,
    zodValidation: emailValidation,
  } = useHandleInput<string>({
    initialValue: '',
    schema: emailSchema,
  });

  const {
    value: password,
    handleValue: handlePassword,
    error: passwordError,
    zodValidation: passwordValidation,
  } = useHandleInput<string>({
    initialValue: '',
    schema: passwordSchema,
  });

  const loading = useLogin((state) => state.loading);
  const { error, errorData } = useLogin((state) => state);
  const handleLogin = useLogin((state) => state.execute);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (loading) return;

    const isPasswordValid = passwordSchema.safeParse(
      sanitizeInput(password),
    ).success;
    const isEmailValid = emailSchema.safeParse(sanitizeInput(email)).success;

    if (isPasswordValid && isEmailValid) {
      handleLogin({ email, password, login });
    } else {
      emailValidation();
      passwordValidation();
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.logo_container}>
        <Logo className={styles.logo} />
        <div className={styles.version_container}>
          <Version />
        </div>
      </div>
      <div className={styles.container}>
        <form onSubmit={handleSubmit} className={styles.block}>
          <div className={styles.title_block}>
            <h2 className={styles.title}>Вход</h2>
            <p className={styles.subtitle}>
              Введите почту и пароль, чтобы войти в систему{' '}
            </p>
          </div>
          <div className={styles.input_container}>
            <div>
              <Input
                placeholder="Эл.почта"
                value={email}
                onChange={(e) => handleEmail(e.target.value)}
              />
              {emailError && <ErrorDescription text={emailError} />}
            </div>
            <div>
              <PasswordInput
                value={password}
                setValue={handlePassword}
                autoComplete="current-password"
              />
              {passwordError && <ErrorDescription text={passwordError} />}
            </div>
            {error && <ErrorDescription text={errorData ?? 'Ошибка'} />}
          </div>
          <div className={styles.action_block}>
            <Button
              state={loading ? 'pending' : 'idle'}
              type="submit"
              fullWidth
              disabled={loading}
            >
              Войти
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};
