import { ROUTES } from '@constants/index';
import { Login } from '@features/auth';
import { Layout } from '@widgets/layout/Layout';
import { createBrowserRouter, Navigate } from 'react-router-dom';
import { AdminRouter } from './AdminRoute';
import { GuestRoute } from './GuestRoute';
import { PrivateRouter } from './PrivateRoute';
import { ApplicationLazy, DetailLazy, UsersLazy } from './lazy';

export const router = createBrowserRouter([
  {
    element: <GuestRoute />,
    children: [
      {
        path: ROUTES.LOGIN,
        element: <Login />,
      },
      {
        path: '*',
        element: <Navigate to={ROUTES.LOGIN} />,
      },
    ],
  },
  {
    element: <PrivateRouter />,
    children: [
      {
        element: <Layout />,
        children: [
          {
            index: true,
            element: <Navigate to={ROUTES.APPLICATIONS} replace />,
          },
          {
            path: ROUTES.APPLICATIONS,
            element: <ApplicationLazy />,
          },
          {
            path: ROUTES.DETAIL,
            element: <DetailLazy />,
          },
          {
            element: <AdminRouter />,
            children: [
              {
                path: ROUTES.USERS,
                element: <UsersLazy />,
              },
            ],
          },
          {
            path: '*',
            element: <Navigate to={ROUTES.APPLICATIONS} />,
          },
        ],
      },
    ],
  },
]);
