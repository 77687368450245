import { FunctionComponent } from 'react';
import styles from './ErrorDescription.module.scss';

interface Props {
  text: string;
}

export const ErrorDescription: FunctionComponent<Props> = ({ text }) => (
  <span className={styles.description}>{text}</span>
);
